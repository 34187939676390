import styles from "../../Styles/appLayout.module.css";
import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../Assets/logo/logo.svg";
import { ReactComponent as LogoText } from "../../Assets/logo/logo_text.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as Search } from "../../Assets/icon/search-svgrepo-com.svg";
import axios from "axios";
import callApi from "../../ApiCall/callApi";

const Nav = () => {
  const location = useLocation().pathname.split("/")[1];
  const [menu, setMenu] = useState(false);
  const [focus, setFocus] = useState(false);
  const [onResults, setOnResults] = useState(false);
  const [mobModal, setMobModal] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(search);
      if (search) {
        setLoading(true);
      }
      if ((search && focus) || (search && mobModal)) {
        axios({
          method: "get",
          url: `https://backend.theholidayfeed.com/api/blogs/?keyword=${search}`,
        })
          .then((res: any) => {
            console.log(res);
            setSearchResult(res.data.blogs);
            setLoading(false);
          })
          .catch((err: any) => {
            console.log(err.response.data.message);
            setSearchResult([]);
            setLoading(false);
          });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <>
      <div
        className={styles.nav_container}
        style={
          menu
            ? { background: "white" }
            : {
                background: "rgba(255, 253, 249, 0.5)",
                backdropFilter: "blur(5px)",
              }
        }
      >
        {/* send logo here to attach here */}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        >
          <Logo className={styles.logo} />
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <ul className={styles.right_nav_elements}>
          <li
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </li>
          <li
            onClick={() => {
              if (location !== "") {
                navigate("/#trending");
              } else {
                document
                  .getElementById("trending")
                  ?.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Trending
          </li>
          <li
            onClick={() => {
              if (location !== "") {
                navigate("/#featured");
              } else {
                document
                  .getElementById("featured")
                  ?.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Featured
          </li>
          <li
            onClick={() => {
              navigate("/latest");
            }}
          >
            Latest
          </li>
        </ul>
        <div className={styles.search_container_main}>
          <div
            className={styles.search_container}
            style={focus ? { background: "white" } : {}}
          >
            <input
              type="text"
              placeholder="Search the holiday feed"
              onFocus={() => setFocus(true)}
              onBlur={() => {
                if (!onResults) {
                  setFocus(false);
                }
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Search />
            {focus && search && (
              <div className={styles.search_results_container}>
                {loading ? (
                  <div
                    style={{
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className="loader_search"></span>
                  </div>
                ) : searchResult.length === 0 ? (
                  <div
                    style={{
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1rem",
                        textDecoration: "none !important",
                        color: "rgba(0,0,0,.5)",
                      }}
                    >
                      No blog found
                    </p>
                  </div>
                ) : (
                  <ul
                    onMouseEnter={() => setOnResults(true)}
                    onMouseLeave={() => setOnResults(false)}
                  >
                    {searchResult.map((val: any, index: any) => {
                      return (
                        <li
                          key={index + "_nav_search"}
                          onClick={() => {
                            navigate(`/blog/${val._id}`);
                            setFocus(false);
                            setOnResults(false);
                          }}
                        >
                          {val.title}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        {!mobModal && (
          <Search
            className={styles.mob_search_icon}
            onClick={() => setMobModal((val) => !val)}
          />
        )}
        <div
          className={styles.menu_btn}
          onClick={() => {
            setMenu((val) => !val);
          }}
        >
          <div className={`${styles.one} ${menu ? styles.one_move : {}}`}></div>
          <div
            className={`${styles.two}  ${menu ? styles.two_move : {}}`}
          ></div>
          <div
            className={`${styles.three} ${menu ? styles.three_move : {}}`}
          ></div>
        </div>
      </div>
      <div
        className={`${styles.small_menu} ${menu ? styles.small_menu_open : {}}`}
      >
        {menu && (
          <div className={styles.small_menu_content}>
            <ul className={styles.right_nav_elements_mob}>
              <li
                onClick={() => {
                  setMenu(false);
                  navigate("/#trending");
                }}
              >
                Trending
              </li>
              <li
                onClick={() => {
                  setMenu(false);
                  navigate("/#featured");
                }}
              >
                Featured
              </li>
              <li
                onClick={() => {
                  setMenu(false);
                  navigate("/latest");
                }}
              >
                Latest
              </li>
            </ul>
          </div>
        )}
      </div>

      <div className={`${styles.menu} ${mobModal ? styles.menu_open : {}}`}>
        <div className={styles.mob_modal}>
          <div className={styles.mob_modal_input}>
            <input
              type="text"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            <Search />
          </div>

          {loading ? (
            <div
              style={{
                height: "200px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="loader_search"></span>
            </div>
          ) : searchResult.length === 0 ? (
            <div
              style={{
                height: "200px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "1rem",
                  textDecoration: "none !important",
                  color: "rgba(0,0,0,.5)",
                }}
              >
                No Result Found
              </span>
            </div>
          ) : (
            <ul>
              {searchResult.map((val: any, index: any) => {
                return (
                  <li
                    key={index + "_nav_search_mob"}
                    onClick={() => {
                      navigate(`/blog/${val._id}`);
                      setFocus(false);
                      setOnResults(false);
                      setMobModal(false);
                    }}
                    style={{ fontSize: "16px" }}
                  >
                    {val.title}
                  </li>
                );
              })}
            </ul>
          )}
          <p
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              fontWeight: 600,
              textDecoration: "underline",
              textTransform: "uppercase",
              textAlign: "center",

              position: "absolute",
              bottom: "0",
              height: "50px",
              left: "50%",
              transform: "translate(-50%)",
            }}
            onClick={() => setMobModal(false)}
          >
            Close
          </p>
        </div>
      </div>
    </>
  );
};

export default Nav;
