import React, { Component } from "react";

import AppLayout from "../../AppLayout";
import Hero from "../../Components/common/hero";
import Slider from "../../Components/common/slider";

import styles from "../../Styles/homepage.module.css";
import { ScrollRestoration } from "react-router-dom";

import { ReactComponent as Right } from "../../Assets/right.svg";
import callApi from "../../ApiCall/callApi";
import withRouter from "../../Util/withRouter";
import axios from "axios";
import checkForImageType from "../../Util/checkForImageType";
import { sortIt } from "../../Util/utils";

type props = {
  trending: any[];
  featured: any[];
  all: any[];
  hero: any;
  discover: any;
  discover2: any;
  ads: any[];
};

class Homepage extends Component<any, props> {
  state = {
    trending: [],
    featured: [],
    all: [],
    hero: {},
    discover: {},
    discover2: {},
    ads: [],
  };

  componentDidMount(): void {
    this.getData();
  }

  getData = () => {
    callApi({ method: "get", url: "" })
      .then((response: any) => {
        let blog_list = sortIt(response.data.blogs);

        this.setState({
          featured: sortIt(
            blog_list.filter((val: any) => {
              if (val.blogType === "featured") {
                return true;
              }
              return false;
            })
          ),
          trending: sortIt(
            blog_list.filter((val: any) => {
              if (val.blogType === "trending") {
                return true;
              }
              return false;
            })
          ),
          all: sortIt(blog_list),
        });

        for (let x of blog_list) {
          if (x.blogCategory === "hero") {
            console.log("x is : ", x);
            this.setState({ hero: x });
          }
        }

        for (let x of blog_list) {
          if (x.blogCategory === "discover-1") {
            this.setState({ discover: x });
            break;
          }
        }
        for (let x of blog_list) {
          if (x.blogCategory === "discover-2") {
            this.setState({ discover2: x });
            break;
          }
        }
        this.getAds();
      })
      .catch((error: any) => {});
  };

  getAds = () => {
    axios({
      method: "get",
      url: "https://backend.theholidayfeed.com/api/advertisements/getadsforhome",
    })
      .then((res: any) => {
        this.setState({
          ads: res.data.advertisements.sort(
            (a: any, b: any) => a.priority - b.priority
          ),
        });
      })
      .catch((err: any) => {
        console.log("something went wrong : ", err);
      });
  };

  render() {
    const width = window.innerWidth;
    return (
      <>
        <ScrollRestoration />
        <Hero data={this.state.hero} />
        <Slider
          style={{ padding: "50px 0", marginTop: "15px" }}
          textStyle={{
            color: "#023c40",
            fontWeight: 400,
            fontSize: "35px",
            letterSpacing: "3px",
          }}
          heading="TRENDING"
          id="trending"
          data={this.state.trending.splice(0, 8)}
          showBtn={true}
          btnTxt={"View All"}
          onBtnClick={() => {
            this.props.router.navigate("/trending");
          }}
        />

        <div className={styles.first_content_container}>
          <div
            className={styles.image_blog_container}
            style={
              // @ts-ignore
              this.state.discover?.images
                ? {
                    background: `url('${checkForImageType(
                      // @ts-ignore
                      this.state?.discover.images[0]
                    )}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }
                : {}
            }
          >
            <div className={styles.first_text_content}>
              <h2>
                {/* @ts-ignore */}
                {this.state.discover?.title
                  ? // @ts-ignore
                    this.state.discover.title
                  : ""}
              </h2>
              <div
                className={styles.btn_container}
                onClick={() => {
                  this.props.router.navigate(
                    // @ts-ignore
                    `/blog/${this.state.discover._id}`
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <button style={{ cursor: "pointer" }}>Discover more</button>

                <div className={styles.circle}>
                  <Right style={{ height: "24px", width: "24px" }} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ads_blog} style={{ position: "relative" }}>
            {this.state.ads.length > 2 ? (
              <>
                <p>Advertisement</p>
                <div
                  className={styles.ads_first}
                  style={{
                    background: `url('${checkForImageType(
                      // @ts-ignore
                      this.state.ads[0]?.couponImage
                    )}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {/* @ts-ignore */}
                  <a href={this.state.ads[0]?.clickUrl} target="_blank"></a>
                  <div className={styles.background_gradient_for_ads}></div>

                  {/* @ts-ignore */}
                  <p>{this.state.ads[0]?.title}</p>
                </div>
                <div
                  className={styles.ads_first}
                  style={{
                    background: `url('${checkForImageType(
                      // @ts-ignore
                      this.state.ads[1]?.couponImage
                    )}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {/* @ts-ignore */}
                  <a href={this.state.ads[1]?.clickUrl} target="_blank"></a>
                  <div className={styles.background_gradient_for_ads}></div>
                  {/* @ts-ignore */}
                  <p>{this.state.ads[1]?.title}</p>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div id="home_featured">
          <Slider
            style={{ background: "#D95858", padding: "30px 0" }}
            textStyle={{
              color: "#fffdf9",
              fontWeight: 400,
              fontSize: "35px",
              letterSpacing: "3px",
            }}
            heading="FEATURED"
            id="featured"
            data={this.state.featured.splice(0, 8)}
            showBtn={true}
            btnTxt={"View All"}
            onBtnClick={() => {
              this.props.router.navigate("/featured");
            }}
            btnStyle={{ color: "white" }}
          />
        </div>
        <div style={{ height: "30px", background: "#D95858" }}></div>
        <div className={styles.ds2_container}>
          <div className={styles.ds2_text_container}>
            <h1>
              {/* @ts-ignore */}
              {this.state.discover2?.title
                ? // @ts-ignore
                  this.state.discover2.title
                : ""}
            </h1>
            <p style={{ textAlign: "justify" }}>
              {/* @ts-ignore */}
              {this.state.discover2?.shortDescription
                ? // @ts-ignore
                  width < 500
                  ? // @ts-ignore
                    this.state.discover2?.shortDescription
                      .split("")
                      .splice(0, 250)
                      .join("") + "..."
                  : // @ts-ignore
                    this.state.discover2?.shortDescription
                : ""}
            </p>
            <div
              className={styles.btn_container}
              onClick={() => {
                this.props.router.navigate(
                  // @ts-ignore
                  `/blog/${this.state.discover2._id}`
                );
              }}
              style={{ cursor: "pointer" }}
            >
              <button style={{ cursor: "pointer" }}>Discover more</button>

              <div className={styles.circle}>
                <Right style={{ height: "24px", width: "24px" }} />
              </div>
            </div>
          </div>
          <div className={styles.ds2_image_container}>
            {/* image part */}
            <div
              className={styles.ds2_image}
              style={
                // @ts-ignore
                this.state.discover2?.images
                  ? // @ts-ignore
                    {
                      background: `url('${
                        // @ts-ignore
                        checkForImageType(this.state.discover2?.images[0])
                      }')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }
                  : {}
              }
            ></div>
            {/* absolute part */}
            <div className={styles.ds2_ab1}></div>
            <div className={styles.ds2_ab2}></div>
          </div>
        </div>
        <div className={styles.ads_section} style={{ position: "relative" }}>
          <p>Advertisment</p>
          {this.state.ads.length >= 3 ? (
            <>
              {/* <p style={{ gridColumn: "1/3" }}>Advertisement</p> */}
              <div
                className={styles.ads_section_1}
                style={{
                  background: `url('${checkForImageType(
                    // @ts-ignore
                    this.state.ads[2]?.couponImage
                  )}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* @ts-ignore */}
                <a href={this.state.ads[2]?.clickUrl} target="_blank"></a>
                <div className={styles.background_gradient_for_ads}></div>
                {/* @ts-ignore */}
                <p>{this.state.ads[2]?.title}</p>
              </div>
              <div
                className={styles.ads_section_1}
                style={{
                  background: `url('${checkForImageType(
                    // @ts-ignore
                    this.state.ads[3]?.couponImage
                  )}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* @ts-ignore */}
                <a href={this.state.ads[3]?.clickUrl} target="_blank"></a>
                <div className={styles.background_gradient_for_ads}></div>
                {/* @ts-ignore */}
                <p>{this.state.ads[3]?.title}</p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            width: "100%",
            position: "relative",
            height: "fit-content",
            paddingBottom: "30px",
          }}
        >
          <Slider
            style={{ padding: "30px 0" }}
            textStyle={{
              color: "white !important",
              fontWeight: 400,
              fontSize: "35px",
              letterSpacing: "3px",
            }}
            heading="LATEST"
            data={this.state.all.splice(0, 8)}
            showBtn={true}
            btnTxt="View All"
            onBtnClick={() => {
              this.props.router.navigate("/latest");
            }}
          />
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "30%",
              bottom: "0",
              background: "#F7D08A",
              zIndex: -10,
            }}
          ></div>
        </div>
      </>
    );
  }
}

export default withRouter(Homepage);
