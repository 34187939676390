import React, { useEffect, useState } from "react";
import Hero from "../../Components/common/hero";
import Card from "../../Components/common/card";
import { ScrollRestoration } from "react-router-dom";
import styles from "../../Styles/latest.module.css";
import callApi from "../../ApiCall/callApi";
import { sortIt } from "../../Util/utils";

const Trending = () => {
  const [data, setData]: [any, any] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    callApi({ method: "get", url: "" }).then((res: any) => {
      setData(
        sortIt(
          res.data.blogs.filter((val: any) => {
            return val.blogType === "trending";
          })
        )
      );
    });
  };

  return (
    <>
      {data ? (
        <>
          <ScrollRestoration />
          <Hero data={data[0]} />
          <div className={styles.grid_container}>
            {data.map((val: any, index: any) => {
              return (
                <Card
                  key={index}
                  data={val}
                  style={{ marginLeft: 0, width: "100%", cursor: "pointer" }}
                />
              );
            })}
          </div>
        </>
      ) : (
        <div style={{ height: "100vh" }}></div>
      )}
    </>
  );
};

export default Trending;
