const checkForImageType = (file: string)=>{

    let type = file.split('.')[file.split('.').length - 1]

    if(type === "gif"){
        return process.env.REACT_APP_IMAGE_LINK + file
    }

    return process.env.REACT_APP_IMAGE_BASE_LINK + file

}

export default checkForImageType;