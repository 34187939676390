import React from "react";
import { ScrollRestoration } from "react-router-dom";
import Hero from "../../Components/common/hero";
import styles from "../../Styles/aboutUs.module.css";

const TermsAndConditions = () => {
  return (
    <>
      <ScrollRestoration />
      <div className={styles.main}>
        <div className={styles.hero}>About Us</div>
        <div className={styles.content}>
          <div className={styles.page}></div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
