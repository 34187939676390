import React from "react";
import styles from "../../Styles/card.module.css";
import { useNavigate } from "react-router-dom";
import { reduceChar } from "../../Util/utils";
import checkForImageType from "../../Util/checkForImageType";

type props = {
  style?: any;
  data?: any;
};

const Card = (props: props) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.card_container}
      style={
        props?.data
          ? {
              cursor: "pointer",
              ...props.style,
            }
          : { cursor: "pointer", ...props.style }
      }
      onClick={() => {
        navigate(`/blog/${props.data._id}`);
      }}
    >
      <img src={checkForImageType(props.data.images[0])} alt=""></img>
      {props?.data ? (
        <div className={styles.card_content}>
          <p>{reduceChar(props.data.title, 100)}</p>
          <p style={{ marginTop: "30px" }}>Read more</p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Card;
