import React, { Component } from "react";
import styles from "../../Styles/hero.module.css";
import withRouter from "../../Util/withRouter";
import { ReactComponent as Right } from "../../Assets/right.svg";
import styless from "../../Styles/homepage.module.css";
import checkForImageType from "../../Util/checkForImageType";

class Hero extends Component<any, any> {
  state = {
    image_link: "",
  };

  render() {
    return (
      <div
        className={styles.container + " _hero"}
        style={
          !!this.props?.data.images
            ? {
                background: `url('${checkForImageType(
                  this.props.data.images[0]
                )}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                ...this.props.style,
              }
            : {
                background: "#f7d08a",
                backgroundSize: "cover",
                backgroundPosition: "center",
                ...this.props.style,
              }
        }
      >
        <div
          className={styles.container}
          style={
            !!this.props?.data.images
              ? {
                  background: "rgba(15,15,15,.4)",
                  height: "100%",
                  width: "100%",
                  padding:
                    this.props.type !== "two" ? "70px 3vw" : "0 3vw 70px 3vw",
                }
              : {
                  padding:
                    this.props.type !== "two" ? "70px 3vw" : "0 3vw 70px 3vw",
                }
          }
        >
          {this.props?.data.title ? (
            <>
              <h1>{this.props.data.title}</h1>
              {this.props.type !== "two" && (
                <>
                  <div
                    className={styless.btn_container}
                    onClick={() => {
                      this.props.router.navigate(
                        // @ts-ignore
                        `/blog/${this.props.data._id}`
                      );
                    }}
                    id="discover_container"
                    style={{ cursor: "pointer" }}
                  >
                    <button style={{ cursor: "pointer" }}>Discover more</button>

                    <div className={styless.circle} id="circle">
                      <Right style={{ height: "24px", width: "24px" }} />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Hero);
