import { ScrollRestoration } from "react-router-dom";
import styles from "../../Styles/aboutUs.module.css";

const AboutUs = () => {
  return (
    <>
      <ScrollRestoration />
      <div className={styles.main}>
        <div className={styles.hero}>About Us</div>
        <div className={styles.content}>
          <div className={styles.page}>
            Welcome to The Holiday Feed, a subsidiary division of The Digital
            Media Feed, where we are dedicated to providing you with all the
            information you need for your travel needs. Our global platform acts
            as a traveler’s guide, providing you with all the necessary
            information regarding flights, travel agencies, hotels, inexpensive
            vacations, and many more. <br />
            <br />
            Our mission is to make travel planning easy and accessible for
            everyone. We understand that planning a trip can be stressful and
            time-consuming, which is why we aim to simplify the process for you.
            Our platform is designed to provide you with all the information you
            need in one place, making it easy for you to plan your next
            adventure.
            <br />
            <br />
            At The Holiday Feed, we are committed to providing you with accurate
            and up-to-date information. Our team of travel experts works
            tirelessly to ensure that all the information on our platform is
            reliable and trustworthy. We believe that everyone deserves to have
            access to the best travel information, and we are dedicated to
            making that a reality.
            <br />
            <br /> Our goal is to establish ourselves as the largest travel
            discussion forum in the world, covering all aspects of travel. We
            want to create a community where travelers can share their
            experiences, ask questions, and connect with other like-minded
            individuals. Our platform is designed to be interactive, allowing
            you to engage with other travelers and share your own travel
            stories.
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
