import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppLayout from "./AppLayout";
import Homepage from "./Pages/Homepage";
import Latest from "./Pages/Latest";
import BlogDetails from "./Pages/BlogDetails";
import TermsAndConditions from "./Pages/Terms&Conditions";
import PrivacyAndPolicy from "./Pages/PrivacyPolicy";
import ContactUs from "./Pages/ContactUs";
import Trending from "./Pages/Trending";
import Featured from "./Pages/Featured";
import AboutUs from "./Pages/AboutUs";
import TermsOfUse from "./Pages/TermsOfUse";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <h1>Error Occured</h1>,
    children: [
      { index: true, element: <Homepage /> },
      { path: "/latest", element: <Latest /> },
      { path: "/blog/:id", element: <BlogDetails /> },
      { path: "/terms-and-conditions", element: <TermsAndConditions /> },
      { path: "/privacy-and-policy", element: <PrivacyAndPolicy /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/trending", element: <Trending /> },
      { path: "/featured", element: <Featured /> },
      { path: "about-us", element: <AboutUs /> },
      { path: "/terms-of-use", element: <TermsOfUse /> },
    ],
  },
]);

root.render(
  <>
    <RouterProvider router={router} />
  </>
);
