import { ScrollRestoration } from "react-router-dom";
import styles from "../../Styles/aboutUs.module.css";

const TermsOfUse = () => {
  return (
    <>
      <ScrollRestoration />
      <div className={styles.main}>
        <div className={styles.hero}>Terms of Use</div>
        <div className={styles.content}>
          <div className={styles.page}>
            <div>
              <h3>ACCEPTANCE OF TERMS</h3>
              <p>
                The Holiday Feed (hereinafter collectively, “The Holiday Feed”/
                “we”/ “us”/ “our”) is a platform owned and run by The Digital
                Media Feed for the purpose of providing online content related
                to your travel needs on our website www.theholidayfeed.com
                (hereinafter collectively the “Website”) for our users
                (hereinafter collectively, “User”/ “You”/” your”/” yours”). By
                using the Website, you agree to have read and understood the
                Terms of Use and you agree to be bound by all its terms. This
                Term of Use along with our Privacy Policy read together shall
                constitute the whole and legally binding Agreement governing the
                use of our services.{" "}
              </p>
            </div>

            <div>
              <h3>DEFINITITIONS</h3>
              <p>
                User” mean individuals who uses or interacts with our website.
              </p>
              <p>
                “Content” includes (but not limited to) articles, blogs, images,
                graphics, videos, and any other materials created and published
                on our website.
              </p>
              <p>
                “Contributors or Content writers” means independent individuals
                contributing content for the purpose of publishing it on the
                website.
              </p>
            </div>

            <div>
              <h3>SERVICES</h3>
              <p>
                The Holiday Feed is a website that is engaged in the business of
                providing content relating to pets. Our services may be
                associated with or provide advertisements of third-party
                websites, services, applications, platforms, and/or content. We
                do not have control over those third-party services or content,
                and we do not review before publishing such content. It is
                important for you to review the terms of use and privacy
                policies that apply to such third-party services.
              </p>
            </div>
            <div>
              <h3>USE OF WEBSITE</h3>
              <p>
                In order to use the Services, it is necessary for you to comply
                with all relevant laws. If any laws applicable to you limits or
                prohibit your access or use of the Services, you must adhere to
                those legal restrictions or cease to use the Services. By
                agreeing to use the services, you acknowledge that they are
                intended solely for your personal, non-profit use and are
                provided for informational and entertainment objectives only.
                The content provided by us should not be considered as monetary,
                expert, legal or medical advice, and it should not be used for
                any other objectives or purposes as mentioned in the Terms of
                Use.{" "}
              </p>
              <p>
                We have no obligation or necessity of reviewing the content
                before publishing it on our website. The content on our website
                shall not be relied upon as it is solely for the purpose of
                general information and should not be understood as a unique or
                your customized needs.
              </p>
              <p>
                Our website entails content contributed by independent content
                writers or contributors which may not be updated or frequently
                reviewed. In spite of the fact that the contributed content is
                reviewed during its submission, we do not make any
                representation or guarantee or take no responsibility for such
                content.{" "}
              </p>
              <p>
                If you are submitting or posting any such content on the
                website, you agree and acknowledge that:
              </p>
              <ul>
                <li>
                  any contributed content posted by you may be present publicly
                  available, or
                </li>
                <li>
                  you hold the rights, interest and title to the content
                  including but not restricted to authorization, consent, or
                  license from any third-party required by you in order to post,
                  publish or submit such content.
                </li>
              </ul>

              <p>
                The contributors or content writers of the content are not
                employed, directly or indirectly by us in any capacity and we do
                not give any Guarantee or warrantee for the worth of the content
                provided or that our website’s content is accurate, true,
                complete, non-misleading or legal. You agree and acknowledge
                that any direct association between you and the contributor or
                content writer shall be at your own risk, and we shall not be
                held liable for any representation or guarantees made by the
                contributor or content writer. You represent and warrant that
                such contributed content shall not infringe or violate any
                Existing executed Contract, Intellectual Properly Laws, Date
                Privacy Laws, Information Technology Laws, or any other
                applicable laws. You further acknowledge that you shall not post
                any prohibited content such as unlawful, abusive, pornographic,
                harassing, obsene or libellous content on our website. You
                understand and accept that any Content you share publicly or
                privately on the website is your sole responsibility. The
                Holiday Feed reserves the right to terminate, suspend, reject,
                take legal action against content provided by the contributor or
                against the contributor.
              </p>

              <p>
                You agree not to launch any virus or harmful element
                intentionally or negligently, or engage in any actions that
                would disturb, impair, or damage The Holiday Feed's Services or
                connected network, or disrupt or interfere with any individual
                or entity's use of these services. Additionally, you agree not
                to disrupt, interfere with, or attempt to gain unauthorized
                access to any part of The Holiday Feed, including its computer
                systems, servers, or networks, in a manner that unreasonably
                burdens or excessively loads them.
              </p>

              <p>
                We might publish advertisements of third-parties, third-party
                links, or contents on our website. The advertisements are solely
                for the purpose of promotion and publicity. We shall not be held
                liable for any services, products or information provided by
                such third-party and we do not take any guarantee for the same.
                You agree and acknowledge that any of your purchase or your
                direct association with such third-party shall be solely based
                on your own risk and you shall be responsible for verifying the
                reliability and accuracy of such third-party website,
                application, products, or services.
              </p>
            </div>
            <div>
              <h3>LICENSE GRANT</h3>
              <p>
                Subject to your compliance with The Holiday Feed Terms of use or
                Privacy Policy, we hereby grant you a limited, non-transferable,
                non-exclusive license to use or access our services for your
                personal use. Notwithstanding anything mentioned above, we shall
                remain the sole owner of all the rights, interest and title in
                the website and reserve all the rights apart from any mentioned
                in the term of use. We reserve the right, in our sole
                discretion, to modify, change, amend, terminate, or discontinue
                the website of its content, any particular function or services
                provided by us at any time and without any prior notice to you.
                We do not grant license to our users to reproduce or publish the
                content publishes or belonging to our website for their
                commercial purposes.
              </p>
              <p>
                In case you post any content on our website, you hereby grant us
                a worldwide, royalty-free, transferable, interminable license to
                use you content with regards to our services. This includes the
                rights to copy, transmit, publish, reproduce, amend, and reform
                your content, as well as embody it into a collective work. We
                also reserve the right to sublicense any part or all our license
                rights to others.
              </p>
            </div>
            <div>
              <h3>DISCLAIMER AND LIMITATION OF LIABILITY</h3>
              <p>
                The Holiday Feed provides serviced on 'as is' and 'as available'
                basis. We shall not be held liable or responsible for the
                correctness of any content including but not limited to
                information posted on our website. We do not provide any
                Guarantee or warrantee for the worth of services provided or
                that our website's content is true, complete, non-misleading or
                legal. We hereby disclaim other representations, warranties or
                guarantees, or liability for any damages whether express or
                implied including but not limited to the (a) fitness of the
                content on our website (b) accuracy or reliability of any
                advice, information, statement, opinion, or third-party
                advertisement. The content or information provided on our
                website shall not be misinterpreted or substituted for any
                professional or expert advice.
              </p>
            </div>
            <div>
              <strong>
                BY USING THE WEBSITE, YOU AGREE AND ACKNOWLEDGE THAT YOU ARE
                USING THE WEBSITE AT YOUR SOLE RISK AND NEITHER THE HOLIDAY FEED
                NOR THE DIGITAL MEDIA FEED OR ITS AFFILIATES SHALL BE HELD
                LIABLE FOR ANY FORM OF DIRECT, SPECIAL, INCIDENTAL, INDIRECT, OR
                CONSEQUENTIAL DAMAGES RELATED TO THIS TERMS OF USE OR YOUR USAGE
                OF THE WEBSITE OR CONTENT BY CONTRIBUTOR OR CONTENT WRITER. THIS
                HOLDS TRUE EVEN IF THE HOLIDAY FEED OR THE DIGITAL MEDIA FEED
                HAD PRIOR KNOWLEDGE OF THE POSSIBILITY OF SUCH DAMAGES. YOU
                AGREE TO WAIVE ALL CLAIMS AGAINST THE HOLIDAY FEED AND THE
                DIGITAL MEDIA FEED AND ITS AFFILIATES INCLUDING THE CONTENT
                CONTRIBUTORS OR CONTENT WRITERS.{" "}
              </strong>
            </div>

            <div>
              <h3>INDEMNIFICATION</h3>
              <p>
                You agree to indemnify and hold The Holiday Feed and The Digital
                Media Feed, its officers, directors, employees, and affiliates
                harmless from and against any and all liabilities including
                claims, demands, actions, suits, or causes of action arising
                from or related to (i) the violation of the terms stated in this
                Terms of Use by any user (ii) the violation of applicable laws,
                including intellectual property laws by users, (iii) the
                violation of privacy policies by users (iv) any breach by
                contributions or content writers.
              </p>
            </div>

            <div>
              <h3>AMENDMENTS</h3>
              <p>
                We reserve the right, in our sole discretion, to modify, change
                or amend the website of its content, any function or services or
                content provided by us at any time and without any prior notice
                to you. If you continue the use of website, it will account for
                your acceptance of the amended terms of use.
              </p>
            </div>

            <div>
              <h3>APPLICABLE LAW</h3>
              <p>
                The laws of India will govern and interpret this Terms of Use,
                encompassing all actions arising from it. Any matter related to
                this Terms of Use will fall under the exclusive jurisdiction of
                the courts located in New Delhi, India. Any claim or cause of
                action in relation to these terms of use or against The Holiday
                Feed must be filed within one year from the date when the cause
                of action accrued. If such claim or cause of action is filed
                after the threshold period of one year, you agree to waive all
                your rights of enforcing such claim or cause of action.
              </p>
            </div>

            <div>
              <h3>CONTACT US REGARDING ANY GRIVIENCES</h3>
              <p>
                Kindly contact us at our Email ID -
                support@thedigitalmediafeed.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
