import styles from "../../Styles/appLayout.module.css";
import { ReactComponent as Logo } from "../../Assets/logo/logo.svg";
import { ReactComponent as LogoText } from "../../Assets/logo/logo_text.svg";
import { ReactComponent as Facebook } from "../../Assets/facebook.svg";
import { ReactComponent as Insta } from "../../Assets/insta.svg";
import { ReactComponent as Twitter } from "../../Assets/twitter.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Email } from "../../Assets/icon/sms.svg";
import { ReactComponent as Skype } from "../../Assets/icon/Skype - Negative.svg";

const Footer = () => {
  const location = useLocation().pathname.split("/")[1];
  const navigate = useNavigate();

  return (
    <div className={styles.footer_container}>
      <div className={styles.logo_txt_container}>
        <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <Logo style={{ height: "100px" }} />
        </div>
        <div style={{}}>
          <a
            href="mailto:support@thedigitalmediafeed.com"
            style={{ textDecoration: "none", color: "black" }}
            className={styles.footer_s_link}
          >
            <Email />
            support@thedigitalmediafeed.com
          </a>
          <p
            style={{
              textDecoration: "none",
              color: "black",
            }}
            className={styles.footer_s_link}
          >
            <Skype />
            thedigitalmediafeed@gmail.com
          </p>
        </div>
      </div>

      <div className={styles.f_blog_container}>
        <p style={{ fontWeight: 700, fontSize: "24px", lineHeight: "40px" }}>
          Category
        </p>
        <ul className={styles.blog_list}>
          <li
            onClick={() => {
              if (location !== "") {
                navigate("/#trending");
              } else {
                document
                  .getElementById("trending")
                  ?.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Trending
          </li>
          <li
            onClick={() => {
              if (location !== "") {
                navigate("/#featured");
              } else {
                document
                  .getElementById("featured")
                  ?.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Featured
          </li>
          <li
            onClick={() => {
              navigate("/latest");
            }}
          >
            All Blogs
          </li>
        </ul>
      </div>
      <div className={styles.f_support_container}>
        <p
          style={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "40px",
            color: "black",
          }}
        >
          About
        </p>
        <ul className={styles.support_list}>
          <li style={{ color: "rgba(0,0,0)" }}>
            <a
              style={{ color: "rgba(0,0,0)", textDecoration: "none" }}
              onClick={() => navigate("/about-us")}
            >
              About Us
            </a>
          </li>
          <li style={{ color: "rgba(0,0,0)" }}>
            <a
              style={{ color: "rgba(0,0,0)", textDecoration: "none" }}
              onClick={() => navigate("/terms-of-use")}
            >
              Terms of Use
            </a>
          </li>
          <li style={{ color: "rgba(56, 24, 40,.75)" }}>
            <a
              style={{ color: "rgba(0,0,0)", textDecoration: "none" }}
              href="https://www.thedigitalmediafeed.com/policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>
          <li style={{ color: "rgba(0,0,0)" }}>
            <a
              style={{ color: "rgba(0,0,0)", textDecoration: "none" }}
              href="https://www.thedigitalmediafeed.com/terms"
              target="_blank"
            >
              Publisher's Terms
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.social__}>
        <div className={styles.social_container}>
          <div className={styles.social}>
            <h5>Platform By:</h5>
            <p>
              {" "}
              <a
                href="https://www.thedigitalmediafeed.com"
                target="_blank"
                style={{ textDecoration: "none", color: "black" }}
              >
                The Digital Media Feed
              </a>
            </p>
          </div>
        </div>
        <div className={styles.cw_1}>
          <p>©the holiday feed</p>
        </div>
      </div>
      <div className={styles.cw_2}>
        <p>©the holiday feed</p>
      </div>
    </div>
  );
};

export default Footer;
