import React, { useState } from "react";
import styles from "../../Styles/contactUs.module.css";
import { country_phone_code } from "../../Util/data";

const ContactUs = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [description, setDescription] = useState("");

	return (
		<>
			<div className={styles.container}>
				<div className={styles.formContainer}>
					<h1>Contact Us</h1>
					{/* form */}
					<div className={styles.form}>
						<div>
							<p>Name</p>
							<input
								type="text"
								value={name}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
						</div>
						<div>
							<p>Email</p>
							<input
								type="email"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
						</div>
						<div>
							<p>Phone no.</p>
							<div className={styles.phoneNumberContainer}>
								<select>
									{country_phone_code.map((value, index) => {
										return (
											<option key={index} value={value.dial_code}>
												{value.code} ({value.dial_code})
											</option>
										);
									})}
								</select>
								<input
									type="number"
									value={phone}
									onChange={(e) => {
										setPhone(e.target.value);
									}}
								/>
							</div>
						</div>
						<div>
							<p>Description</p>
							<textarea
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
							/>
						</div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<input
								type="checkbox"
								style={{
									height: "20px",
									width: "20px",
									marginRight: "10px",
									marginLeft: "5px",
								}}
							/>
							Terms and conditions
						</div>
						<button className={styles.submitBtn}>Submit</button>
					</div>
				</div>
				<div className={styles.otherContainer}></div>
			</div>
		</>
	);
};

export default ContactUs;
