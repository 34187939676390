import axios from "axios";

type props = {
	url: string | undefined;
	method: string;
	data?: any;
};

const base_url = "https://backend.theholidayfeed.com/api/blogs/";

const callApi = (props: props) => {
	const data = {
		method: props.method,
		url: base_url + props.url,
		data: props?.data ? props.data : {},
	};

	return axios(data);
};

export default callApi;
