import React, { Component } from "react";
import Card from "./card";
import styles from "../../Styles/slider.module.css";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@splidejs/react-splide/css";
import withRouter from "../../Util/withRouter";

type props = {
  style?: any;
  textStyle?: any;
  heading: string;
  id?: string;
  data: any[];
  showBtn?: boolean;
  btnTxt?: string;
  onBtnClick?: any;
  btnStyle?: any;
};

class Slider extends Component<any, props> {
  render() {
    const width = window.innerWidth;

    return (
      <div
        id={this.props?.id ? this.props.id : ""}
        className={styles.slider_container}
        style={this.props?.style ? this.props?.style : {}}
      >
        <div className={styles.heading_and_btn}>
          <h2 style={this.props?.textStyle ? { ...this.props.textStyle } : {}}>
            {this.props.heading}
          </h2>
          {this.props?.showBtn && (
            <button
              onClick={this.props?.onBtnClick}
              style={{ ...this.props?.btnStyle }}
            >
              {this.props.btnTxt}
            </button>
          )}
        </div>
        <div>
          <Splide
            onScroll={(item: any) => {
              console.log(item, "scroll");
            }}
            options={
              width < 500
                ? {
                    arrows: this.props.data.length > 2 ? true : false,
                    // autoWidth: true,
                    perMove: 1,
                    trimSpace: false,
                    gap: "1rem",
                    perPage: 1,
                    type: "loop",
                  }
                : {
                    arrows: this.props.data.length > 2 ? true : false,
                    autoWidth: true,
                    perMove: 1,
                    trimSpace: false,
                    gap: "1rem",
                    type: "loop",
                  }
            }
          >
            {this.props.data.map((val: any, index: any) => {
              return (
                <SplideSlide key={index}>
                  <Card data={val} />
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </div>
    );
  }
}

export default withRouter(Slider);
